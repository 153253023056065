import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateWithoutTimezone',
  standalone: false,
})
export class DateWithoutTimezone implements PipeTransform {
  private _locale: string;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    this._locale = locale;
  }

  transform(value: Date | undefined, format: string) {
    if (value === null || value === undefined) {
      return '';
    }
    return formatDate(value, format, this.locale, 'UTC+0');
  }
}
