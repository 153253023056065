import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TableColumn } from '../../../models/table-column';
import { Subject, Subscription, debounceTime, fromEvent } from 'rxjs';
import { RadioOption } from '../../../models/radio-option';

export type TableColumnFilter = {
  key: string;
  value: string | string[];
};

export type TableColumnSort = {
  field: string;
  direction: 'asc' | 'desc';
};

@Component({
  selector: 'app-table-column-options-dropdown',
  templateUrl: './table-column-options-dropdown.component.html',
  styleUrl: './table-column-options-dropdown.component.scss',
  standalone: false,
})
export class TableColumnOptionsDropdownComponent implements OnInit, OnDestroy {
  @Input() column?: TableColumn;
  @Input() testId = '';
  @Input() position?: number;
  @Input() isOpened = false;
  @Input() bodyWidth: number = 310;
  @Input() bodyPadding: number = 16;
  @Input() currentSorting?: TableColumnSort;

  @Output() filterChanged = new EventEmitter<TableColumnFilter>();
  @Output() sortingChanged = new EventEmitter<TableColumnSort>();

  filter?: TableColumnFilter;
  textFilter = new Subject<string>();
  subscriptions: Subscription[] = [];

  sortOptions: RadioOption<'asc' | 'desc'>[] = [];

  currentWindowWidth = window.innerWidth - this.bodyPadding * 2;

  constructor() {
    this.subscribeToTextFilterChanges();
  }

  ngOnInit(): void {
    if (this.column) {
      this.filter = {
        key: this.column.filterQueryParamName!,
        value: this.column.type === 'text' ? '' : [],
      };

      if (this.column.sortable && this.column.sortOptions) {
        this.sortOptions = [
          {
            value: 'asc',
            label: this.column.sortOptions.ascLabel,
          },
          {
            value: 'desc',
            label: this.column.sortOptions.descLabel,
          },
        ];
      }

      this.subscriptions = [
        ...this.subscriptions,
        fromEvent(window, 'resize').subscribe(() => {
          this.currentWindowWidth = window.innerWidth - this.bodyPadding * 2;
        }),
      ];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  subscribeToTextFilterChanges(): void {
    this.subscriptions = [
      ...this.subscriptions,
      this.textFilter.pipe(debounceTime(500)).subscribe((textFilter) => {
        this.filter!.value = textFilter ? textFilter : '';
        this.notifyFilterWasUpdated();
      }),
    ];
  }

  onTextFilterChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.textFilter.next(target.value);
  }

  notifyFilterWasUpdated() {
    this.filterChanged.emit(this.filter);
  }

  onEnumFilterChanged(value: string | number) {
    const enumFilter = this.filter?.value as string[];

    if (enumFilter.includes(value.toString())) {
      this.filter!.value = enumFilter.filter((x) => x !== value.toString());
    } else {
      this.filter!.value = [...enumFilter, value.toString()];
    }

    this.notifyFilterWasUpdated();
  }

  enumFilterIsSelected(value: string | number) {
    const enumFilter = this.filter!.value || [];
    return enumFilter.includes(value.toString());
  }

  onSortingChanged(direction: 'asc' | 'desc') {
    if (this.column) {
      this.currentSorting = {
        field: this.column.key || this.column.filterQueryParamName || '',
        direction,
      };

      this.sortingChanged.emit(this.currentSorting);
    }
  }

  isCurrentSortingSelected() {
    return (this.column?.key ?? this.column?.filterQueryParamName) ===
      this.currentSorting?.field
      ? this.currentSorting?.direction
      : undefined;
  }

  getContainerPosition() {
    if (!this.position || this.position < 0) {
      return 0;
    }

    const fullContainerWidth = this.bodyWidth + this.bodyPadding;
    if (this.position + fullContainerWidth > this.currentWindowWidth) {
      return this.currentWindowWidth - fullContainerWidth;
    }

    return this.position;
  }
}
