import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/service/menu.service';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from '../../models/dropdown-option';
import { forkJoin, map } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: false,
})
export class HeaderComponent implements OnInit {
  selectedLanguage: DropdownOption | undefined;
  languageOptions: DropdownOption[] = [];

  constructor(
    private menuService: MenuService,
    public translate: TranslateService,
    public authService: AuthService,
    private router: Router
  ) {}

  shouldDisplayButtons = this.checkIfShouldDisplayButtons();

  ngOnInit(): void {
    this.setLanguageOptions().subscribe(() => {
      this.selectedLanguage = this.languageOptions.find(
        (l) => l.value === this.translate.currentLang,
      );
    });
    this.translate.onLangChange.subscribe(() => {
      this.setLanguageOptions().subscribe();
    });
    this.router.events.subscribe(() => {
      this.shouldDisplayButtons = this.checkIfShouldDisplayButtons();
    });
  }

  checkIfShouldDisplayButtons() {
    return (
      this.authService.isAuthenticated() && this.router.url !== '/access-denied'
    );
  }

  toggleMenu() {
    this.menuService.toggleMenu();
  }

  setLanguageOptions() {
    return this.getLanguageOptions().pipe(
      map((options) => {
        this.languageOptions = options;
        return options;
      }),
    );
  }

  getLanguageOptions() {
    const availableLanguages = this.translate.getLangs();

    return forkJoin(
      availableLanguages.map((locale) =>
        this.translate.get(`LANGUAGES.${locale}`).pipe(
          map((translation) => {
            return {
              label: translation,
              value: locale,
              colorStyle: '',
            };
          }),
        ),
      ),
    );
  }

  didChangeLanguage(option: DropdownOption) {
    const language = option.value as 'pt' | 'en';
    this.translate.use(language);
  }

  updateDropdownLanguageText(selectedOption: DropdownOption) {
    return (selectedOption.value as string).toUpperCase();
  }
}
