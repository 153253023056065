import { Component } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header-user-information',
  templateUrl: './header-user-information.component.html',
  styleUrl: './header-user-information.component.scss',
  standalone: false,
})
export class HeaderUserInformationComponent {
  userInfo: AccountInfo;

  constructor(private authService: AuthService) {
    this.userInfo = this.authService.getCurrentUser();
  }

  logout() {
    this.authService.logout();
  }
}
