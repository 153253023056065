import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { CacheBase } from '../models/base/cache.base';
import { FunctionalityService } from './functionality.service';
import {
  AccessLevel,
  FunctionalityName,
  RoleFunctionality,
} from '../models/functionality';

export const USER_CACHE_KEY = 'USER_CACHE';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CacheBase<User> {
  constructor(
    private http: HttpClient,
    private functionalityService: FunctionalityService,
  ) {
    super(USER_CACHE_KEY);
  }

  login(clearCache = false) {
    const request = this.http.get<User>(
      `${environment.auditBackendURL}login`,
      clearCache
        ? {
            headers: {
              'Clear-Site-Data':
                '"cache", "cookies", "storage", "executionContexts"',
            },
          }
        : {},
    );
    return this.handleCacheInRequest(request);
  }

  list() {
    return this.http.get<User[]>(`${environment.auditBackendURL}listAllUsers`);
  }

  create(data: { email: string }) {
    return this.http.post<User>(
      `${environment.auditBackendURL}createUser`,
      data,
    );
  }

  delete(id: string) {
    return this.http.delete<User>(
      `${environment.auditBackendURL}deleteUser?id=${id}`,
    );
  }

  updateRole(userId: string, roleId: string) {
    return this.http.put<User>(`${environment.auditBackendURL}updateUserRole`, {
      userId,
      roleId,
    });
  }

  userHasPermission(identifier: FunctionalityName, level: AccessLevel) {
    const userCache = this.getCache();
    const functionalities = this.functionalityService.getCache();
    const roleFunctionalities = userCache?.role?.roleFunctionalities;

    if (!roleFunctionalities || !functionalities) {
      return false;
    }

    const hasPermission = roleFunctionalities.find(
      (roleFunctionality: RoleFunctionality) => {
        const hasPermission =
          roleFunctionality.functionality.name === functionalities[identifier];
        const hasLevel = roleFunctionality.accessLevel >= level;

        return hasPermission && hasLevel;
      },
    );

    if (hasPermission) {
      return true;
    }

    return false;
  }
}
