import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '../button/button.component';

export type ButtonConfiguration = {
  label: string;
  action: () => void;
  color: ButtonColor;
  variant: ButtonVariant;
  size: ButtonSize;
  grow: boolean;
};

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss',
  standalone: false,
})
export class ConfirmationModalComponent {
  @Input() isOpen = false;
  @Input() popupTitle: string = '';
  @Input() popupWidth = 480;
  @Input() actionContainerStyle: 'row' | 'column' = 'column';
  @Input() actionsContainerCentered = false;
  @Input() buttonConfigurations: ButtonConfiguration[] = [];
  @Output() isOpenChange = new EventEmitter<boolean>();
}
