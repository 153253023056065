import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-temporary-message',
  templateUrl: './temporary-message.component.html',
  styleUrl: './temporary-message.component.scss',
  standalone: false,
})
export class TemporaryMessageComponent implements OnInit {
  @Input() message: string = '';
  @Input() exibitionTime = 3000;
  @Input() kind: 'success' | 'error' = 'success';

  @Output() messageCleared = new EventEmitter<void>();

  show = true;

  ngOnInit() {
    this.clearMessagesAfterDelay();
  }

  private clearMessagesAfterDelay() {
    setTimeout(() => {
      this.show = false;
      this.messageCleared.emit();
    }, this.exibitionTime);
  }
}
