import { Component, inject, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-base-logged-out-page',
  templateUrl: './base-logged-out-page.component.html',
  styleUrl: './base-logged-out-page.component.scss',
  standalone: false,
})
export class BaseLoggedOutPageComponent implements OnInit {
  private authService = inject(AuthService);
  hideButton = true;

  @Input() boxText: string = '';

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.authService.isAuthenticated()) {
        this.hideButton = false;
      }
    }, 500);
  }
}
