export const environment = {
  prod: true,
  msalConfig: {
    auth: {
      clientId: '332ff0bc-819d-404f-bd4d-2c3198c5409e',
      authority: 'https://login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  promotionPowerBIReport: {
    id: '4b989092-f2f9-426e-9aed-1492f0e98cd6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4b989092-f2f9-426e-9aed-1492f0e98cd6',
    interceptor: {
      scopes: [],
      uri: 'https://api.powerbi.com/v1.0/myorg',
    },
  },
  auditBackendURL: "api/"
};
