import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
  standalone: false,
})
export class ToggleComponent {
  @Input() label?: string;
  @Input() toggleName: string = 'toggle';

  @Input() state = false;
  @Output() stateChange = new EventEmitter<boolean>();

  toggleState() {
    this.state = !this.state;
    this.stateChange.emit(this.state);
  }
}
