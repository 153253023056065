<div *ngIf="control" id="input-wrapper" class="input-wrapper" [ngClass]="{ hasValue: control.value }">
  <span class="label" *ngIf="label">
    {{ label }}
    <span class="required-star" *ngIf="controlIsRequired && showRequiredStar">*</span>
  </span>

  @if (type === "date" || type === "date-range") {
    @if (type === "date") {
      <div class="date-wrapper">
        <button class="date-trigger" (click)="picker.open()">
          <img src="../../../../assets/icons/calendar.svg" alt="Calendar" />
        </button>

        <input
          class="input"
          [matDatepicker]="picker"
          [formControl]="control"
          type="text"
          readonly
          [placeholder]="placeholder"
          [attr.data-testid]="testId"
          [min]="minDate"
          [max]="maxDate"
        />
        <mat-datepicker #picker></mat-datepicker>
      </div>
    } @else {
      <div class="date-wrapper" (click)="rangePicker.open()"  (keyup.enter)="rangePicker.open()" tabindex="0">
        <button class="date-trigger" [attr.data-testid]="testId" (click)="rangePicker.open()">
          <img src="../../../../assets/icons/calendar.svg" alt="Calendar" />
        </button>

        @if (rangeValue.start || rangeValue.end) {
          <span class="range-label">{{ rangeValue.start | date: ONLY_DATE }} - {{ rangeValue.end | date: ONLY_DATE }}</span>
        } @else {
          <span class="range-label placeholder">{{ placeholder }}</span>
        }

        <mat-date-range-input class="input" [rangePicker]="rangePicker" [min]="minDate" [max]="maxDate" separator="">
          <input class="range-input" matStartDate (dateChange)="setStartRange($event.value)" />
          <input class="range-input" matEndDate (dateChange)="setEndRange($event.value)" />
        </mat-date-range-input>

        <mat-date-range-picker #rangePicker></mat-date-range-picker>
      </div>
    }
  } @else if (type === "text-area") {
    <textarea [ngStyle]="{ height: textAreaHeight + 'px' }" class="input text-area" [attr.data-testid]="testId" [formControl]="control" [placeholder]="placeholder" [readOnly]="readonly"></textarea>
  } @else {
    <input class="input" [formControl]="control" [attr.data-testid]="testId" [type]="type" [placeholder]="placeholder" [readOnly]="readonly" />
  }

  <div class="errors" *ngIf="control.invalid && control.touched">
    <ng-content select="[errors]"></ng-content>
  </div>
</div>
