import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropdownOption } from '../../models/dropdown-option';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
  standalone: false,
})
export class DropdownComponent implements OnChanges {
  @Input() selectedOption: DropdownOption | undefined;
  @Input() options: DropdownOption[] = [];
  @Input() placeholder: string = '';
  @Input() testId: string = 'dropdown';
  @Input() displayText: ((option: DropdownOption) => string) | undefined;
  @Input() withCheckbox = true;
  @Input() fullSize = false;

  @Output() selectedOptionChange = new EventEmitter<DropdownOption>();

  open = false;
  displayedValue: string = '';
  value: unknown;

  ngOnChanges(changes: SimpleChanges) {
    const selectedOptionChanges = changes['selectedOption'];
    if (
      selectedOptionChanges &&
      selectedOptionChanges.currentValue !== undefined
    ) {
      this.handleValueChange(selectedOptionChanges.currentValue.value);
    }
  }

  handleClick() {
    this.open = !this.open;
  }

  handleValueChange(newValue: unknown) {
    const selectedOption = this.options.find((o) => o.value === newValue);
    this.selectedOption = selectedOption;
    this.selectedOptionChange.emit(selectedOption);

    this.value = newValue;
    this.displayedValue = this.selectedOption?.label ?? '';
    this.open = false;

    if (this.displayText && selectedOption) {
      this.displayedValue = this.displayText(selectedOption);
    }
  }
}
