import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'dateWithCustomTimezone',
  standalone: false,
})
export class DateWithCustomTimezonePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(
    value: Date | null | undefined,
    timeZone: string,
    format: string,
  ): string {
    if (!value) {
      return '';
    }

    const momentWithTimezone = moment.tz(value, timeZone);
    const dateWithTimezone = momentWithTimezone.toDate();
    const timeZoneOffset = momentWithTimezone.zoneName();

    return formatDate(dateWithTimezone, format, this.locale, timeZoneOffset);
  }
}
